import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, Modal, Form, Button, Alert, Card } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import VolumeBots from "./VolumeBots";
import AIBots from "./AIBots";
import Docs from "./Docs";
import { ethers } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import SETTINGS from "./SETTINGS";


function App() {
  const [wallets, setWallets] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [logs, setLogs] = useState([]);

  const [privateKeyInput, setPrivateKeyInput] = useState("");
  const [walletNameInput, setWalletNameInput] = useState("");
  const [showAddWalletModal, setShowAddWalletModal] = useState(false);

  // New states for auth
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState("login"); // or "register"
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(localStorage.getItem('token') || "");

  const provider = new ethers.providers.JsonRpcProvider(
    "https://base-mainnet.infura.io/v3/28556b128e27490484d515a570de94a5"
  );

  useEffect(() => {
    fetchWallets();
    fetchTokens();
  }, []);

  const fetchWallets = async () => {
    try {
      const response = await fetch(SETTINGS.backendUrl+"/api/wallets", {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setWallets(data);
    } catch (error) {
      setLogs((prevLogs) => [`Error fetching wallets: ${error.message}`, ...prevLogs]);
    }
  };

  const fetchTokens = async () => {
    try {
      const response = await fetch(SETTINGS.backendUrl+"/api/tokens", {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setTokens(data);
    } catch (error) {
      setLogs((prevLogs) => [`Error fetching tokens: ${error.message}`, ...prevLogs]);
    }
  };

  const handleAddWallet = async () => {
    if (!walletNameInput || !privateKeyInput) {
      setLogs((prevLogs) => [`Please provide both wallet name and private key.`, ...prevLogs]);
      return;
    }

    try {
      const response = await fetch(SETTINGS.backendUrl+"/api/wallets", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          name: walletNameInput,
          privateKey: privateKeyInput,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setLogs((prevLogs) => [`Wallet added: ${walletNameInput} (${data.address})`, ...prevLogs]);
        setPrivateKeyInput("");
        setWalletNameInput("");
        setShowAddWalletModal(false);
        fetchWallets();
      } else {
        setLogs((prevLogs) => [`Error adding wallet: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error adding wallet: ${error.message}`, ...prevLogs]);
    }
  };

  const generateWallet = () => {
    const wallet = ethers.Wallet.createRandom();
    setPrivateKeyInput(wallet.privateKey);
    setWalletNameInput(`Wallet ${wallet.address.substring(0, 6)}`);
  };

  // Auth handlers
  const handleAuthSubmit = async () => {
    const url = authMode === "login" ? SETTINGS.backendUrl+"/api/login" : SETTINGS.backendUrl+"/api/register";
    const payload = { email, username, password };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (response.ok) {
        if (authMode === "login") {
          // Store token
          localStorage.setItem('token', data.token);
          setToken(data.token);
          setLogs((prevLogs) => [`Logged in as ${username}`, ...prevLogs]);
        } else {
          setLogs((prevLogs) => [`User ${username} registered successfully. You can now log in.`, ...prevLogs]);
        }
        setShowAuthModal(false);
        setUsername("");
        setEmail("");
        setPassword("");
      } else {
        setLogs((prevLogs) => [`Error: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error: ${error.message}`, ...prevLogs]);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken("");
    setLogs((prevLogs) => [`Logged out successfully.`, ...prevLogs]);
  };

  return (
    <Router>
      <Container fluid>
        <Navbar bg="dark" variant="dark" expand="lg">
          <Navbar.Brand as={Link} to="/">Bot Dashboard</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {!token ? (
                <>
                  <Nav.Link onClick={() => { setAuthMode("register"); setShowAuthModal(true); }}>Register</Nav.Link>
                  <Nav.Link onClick={() => { setAuthMode("login"); setShowAuthModal(true); }}>Login</Nav.Link>
                </>
              ) : (
                <>
                <Nav.Link as={Link} to="/volume-bots">Volume Bots</Nav.Link>
              <Nav.Link as={Link} to="/ai-bots">AI Bots</Nav.Link>
              <Nav.Link as={Link} to="/docs">Docs</Nav.Link>
                  <Nav.Link onClick={() => setShowAddWalletModal(true)}>Add Wallet</Nav.Link>
                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/* Auth Modal */}
        <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{authMode === "login" ? "Login" : "Register"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {authMode === "register" &&(
                <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              )}
              
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAuthModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAuthSubmit}>
              {authMode === "login" ? "Login" : "Register"}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Wallet Modal */}
        <Modal show={showAddWalletModal} onHide={() => setShowAddWalletModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Wallet Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter wallet name"
                  value={walletNameInput}
                  onChange={(e) => setWalletNameInput(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Private Key</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter private key"
                  value={privateKeyInput}
                  onChange={(e) => setPrivateKeyInput(e.target.value)}
                />
              </Form.Group>
              <Button variant="secondary" onClick={generateWallet} className="mt-2">
                Generate Wallet
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddWalletModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddWallet}>
              Add Wallet
            </Button>
          </Modal.Footer>
        </Modal>

        <Routes>
          <Route
            path="/"
            element={
              "Hello"
            }
          />
          <Route
            path="/volume-bots"
            element={
              <VolumeBots
                wallets={wallets}
                tokens={tokens}
                fetchTokens={fetchTokens}
                logs={logs}
                setLogs={setLogs}
                provider={provider}
              />
            }
          />
          <Route
            path="/ai-bots"
            element={
              <AIBots
                logs={logs}
                setLogs={setLogs}
              />
            }
          />
          <Route
            path="/docs"
            element={
              <Docs />
            }
          />
        </Routes>

        <Container>
          <Card className="mt-3">
            <Card.Body>
              <Card.Title className="text-center">Logs</Card.Title>
              <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                {logs.map((log, index) => (
                  <Alert key={index} variant="info">
                    {log}
                  </Alert>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Container>
      </Container>
    </Router>
  );
}

export default App;
