import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Modal, Table, Card, Badge } from 'react-bootstrap';
import SETTINGS from "./SETTINGS";

function AIBots() {
  const [aiBots, setAIBots] = useState([]);
  const [showAddAIBotModal, setShowAddAIBotModal] = useState(false);
  const [aiBotName, setAIBotName] = useState('');
  const [aiBotPersonality, setAIBotPersonality] = useState('');
  const [aiBotKnowledge, setAIBotKnowledge] = useState('');
  const [aiBotDelay, setAIBotDelay] = useState(60000);
  const [aiBotPollDelay, setAIBotPollDelay] = useState(300000); // for example, 5 minutes
  const [telegramBotToken, setTelegramBotToken] = useState('');
  const [telegramChatId, setTelegramChatId] = useState('');
  const [xBearerToken, setXBearerToken] = useState('');
  const [logs, setLogs] = useState([]);
  const [postOnX, setPostOnX] = useState(false);

  // For Add Keyword
  const [showAddKeywordModal, setShowAddKeywordModal] = useState(false);
  const [keywordInput, setKeywordInput] = useState('');
  const [selectedBotId, setSelectedBotId] = useState(null);

  useEffect(() => {
    fetchAIBots();
  }, []);

  const fetchAIBots = async () => {
    try {
      const response = await fetch(SETTINGS.backendUrl+'/api/ai-bots');
      const botsData = await response.json();

      // Fetch keywords for each bot
      const botsWithKeywords = await Promise.all(
        botsData.map(async (bot) => {
          const keywordsRes = await fetch(SETTINGS.backendUrl+`/api/ai-bots/${bot.id}/keywords`);
          const keywords = await keywordsRes.json();
          return { ...bot, keywords };
        })
      );

      setAIBots(botsWithKeywords);
    } catch (error) {
      setLogs((prevLogs) => [`Error fetching AI bots: ${error.message}`, ...prevLogs]);
    }
  };

  const handleAddAIBot = async () => {
    if (!aiBotName || !aiBotPersonality || !aiBotDelay || !telegramBotToken || !telegramChatId) {
      setLogs((prevLogs) => ['Please fill in all required fields.', ...prevLogs]);
      return;
    }

    try {
      const response = await fetch(SETTINGS.backendUrl+'/api/ai-bots', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: aiBotName,
          personality: aiBotPersonality,
          knowledge: aiBotKnowledge,
          delay: aiBotDelay,
          poll_delay: aiBotPollDelay,
          telegramBotToken,
          telegramChatId,
          post_on_x: postOnX ? 1 : 0,
          x_bearer_token: xBearerToken,
        }),        
      });
      const data = await response.json();
      if (response.ok) {
        setLogs((prevLogs) => [`AI Bot added: ${aiBotName}`, ...prevLogs]);
        setShowAddAIBotModal(false);
        // Reset fields
        setAIBotName('');
        setAIBotPersonality('');
        setAIBotKnowledge('');
        setAIBotDelay(60000);
        setAIBotPollDelay(300000);
        setTelegramBotToken('');
        setTelegramChatId('');
        fetchAIBots();
      } else {
        setLogs((prevLogs) => [`Error adding AI bot: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error adding AI bot: ${error.message}`, ...prevLogs]);
    }
  };

  const handleStartAIBot = async (botId) => {
    try {
      const response = await fetch(SETTINGS.backendUrl+`/api/ai-bots/${botId}/start`, {
        method: 'POST',
      });
      const data = await response.json();
      if (response.ok) {
        setLogs((prevLogs) => [`AI Bot ${botId} started`, ...prevLogs]);
        fetchAIBots();
      } else {
        setLogs((prevLogs) => [`Error starting AI bot: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error starting AI bot: ${error.message}`, ...prevLogs]);
    }
  };

  const handleStopAIBot = async (botId) => {
    try {
      const response = await fetch(SETTINGS.backendUrl+`/api/ai-bots/${botId}/stop`, {
        method: 'POST',
      });
      const data = await response.json();
      if (response.ok) {
        setLogs((prevLogs) => [`AI Bot ${botId} stopped`, ...prevLogs]);
        fetchAIBots();
      } else {
        setLogs((prevLogs) => [`Error stopping AI bot: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error stopping AI bot: ${error.message}`, ...prevLogs]);
    }
  };

  const handleDeleteAIBot = async (botId) => {
    if (window.confirm('Are you sure you want to delete this AI bot?')) {
      try {
        const response = await fetch(SETTINGS.backendUrl+`/api/ai-bots/${botId}`, {
          method: 'DELETE',
        });
        const data = await response.json();
        if (response.ok) {
          setLogs((prevLogs) => [`AI Bot ${botId} deleted`, ...prevLogs]);
          fetchAIBots();
        } else {
          setLogs((prevLogs) => [`Error deleting AI bot: ${data.error}`, ...prevLogs]);
        }
      } catch (error) {
        setLogs((prevLogs) => [`Error deleting AI bot: ${error.message}`, ...prevLogs]);
      }
    }
  };

  const handleOpenAddKeywordModal = (botId) => {
    setSelectedBotId(botId);
    setKeywordInput('');
    setShowAddKeywordModal(true);
  };

  const handleAddKeyword = async () => {
    if (!keywordInput) {
      setLogs((prevLogs) => [`Please enter a keyword.`, ...prevLogs]);
      return;
    }

    try {
      const response = await fetch(SETTINGS.backendUrl+`/api/ai-bots/${selectedBotId}/keywords`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ keyword: keywordInput }),
      });
      const data = await response.json();
      if (response.ok) {
        setLogs((prevLogs) => [`Keyword "${keywordInput}" added to AI Bot ${selectedBotId}`, ...prevLogs]);
        setShowAddKeywordModal(false);
        fetchAIBots();
      } else {
        setLogs((prevLogs) => [`Error adding keyword: ${data.error}`, ...prevLogs]);
      }
    } catch (error) {
      setLogs((prevLogs) => [`Error adding keyword: ${error.message}`, ...prevLogs]);
    }
  };

  const handleDeleteKeyword = async (botId, keywordId) => {
    if (window.confirm('Are you sure you want to delete this keyword?')) {
      try {
        const response = await fetch(SETTINGS.backendUrl+`/api/ai-bots/${botId}/keywords/${keywordId}`, {
          method: 'DELETE',
        });
        const data = await response.json();
        if (response.ok) {
          setLogs((prevLogs) => [`Keyword ${keywordId} deleted from bot ${botId}`, ...prevLogs]);
          fetchAIBots();
        } else {
          setLogs((prevLogs) => [`Error deleting keyword: ${data.error}`, ...prevLogs]);
        }
      } catch (error) {
        setLogs((prevLogs) => [`Error deleting keyword: ${error.message}`, ...prevLogs]);
      }
    }
  };

  return (
    <Container fluid>
      {/* Add AI Bot Modal */}
      <Modal show={showAddAIBotModal} onHide={() => setShowAddAIBotModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add AI Bot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter bot name"
                value={aiBotName}
                onChange={(e) => setAIBotName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label>Personality</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Describe the bot's personality"
                value={aiBotPersonality}
                onChange={(e) => setAIBotPersonality(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label>Knowledge</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Add extra knowledge/context"
                value={aiBotKnowledge}
                onChange={(e) => setAIBotKnowledge(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label>Delay between posts (ms)</Form.Label>
              <Form.Control
                type="number"
                step="1000"
                placeholder="Enter delay in milliseconds"
                value={aiBotDelay}
                onChange={(e) => setAIBotDelay(Number(e.target.value))}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label>Poll Delay (ms)</Form.Label>
              <Form.Control
                type="number"
                step="1000"
                placeholder="Enter poll delay in milliseconds"
                value={aiBotPollDelay}
                onChange={(e) => setAIBotPollDelay(Number(e.target.value))}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label>Telegram Bot Token</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Telegram bot token"
                value={telegramBotToken}
                onChange={(e) => setTelegramBotToken(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label>Telegram Chat ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Telegram chat ID"
                value={telegramChatId}
                onChange={(e) => setTelegramChatId(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-2">
  <Form.Check 
    type="checkbox" 
    label="Post on X" 
    checked={postOnX} 
    onChange={(e) => setPostOnX(e.target.checked)} 
  />
</Form.Group>

{postOnX && (
  <Form.Group className="mt-2">
    <Form.Label>X Bearer Token</Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter X Bearer Token"
      value={xBearerToken}
      onChange={(e) => setXBearerToken(e.target.value)}
    />
  </Form.Group>
)}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddAIBotModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddAIBot}>
            Add AI Bot
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Keyword Modal */}
      <Modal show={showAddKeywordModal} onHide={() => setShowAddKeywordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Keyword</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Keyword</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter keyword"
              value={keywordInput}
              onChange={(e) => setKeywordInput(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddKeywordModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddKeyword}>
            Add Keyword
          </Button>
        </Modal.Footer>
      </Modal>

      {/* AI Bots List */}
      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="text-center">AI Bots</Card.Title>
          <Button variant="primary" onClick={() => setShowAddAIBotModal(true)}>
            Add AI Bot
          </Button>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Delay (ms)</th>
                <th>Poll Delay (ms)</th>
                <th>Keywords</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {aiBots.map((bot) => (
                <tr key={bot.id}>
                  <td>{bot.id}</td>
                  <td>{bot.name}</td>
                  <td>{bot.status}</td>
                  <td>{bot.delay}</td>
                  <td>{bot.poll_delay}</td>
                  <td>
                    {bot.keywords && bot.keywords.length > 0 ? (
                      bot.keywords.map((kw) => (
                        <Badge key={kw.id} bg="info" className="me-1">
                          {kw.keyword}{' '}
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() => handleDeleteKeyword(bot.id, kw.id)}
                          >
                            x
                          </Button>
                        </Badge>
                      ))
                    ) : (
                      <span>No keywords</span>
                    )}
                  </td>
                  <td>
                    {bot.status === 'running' ? (
                      <Button variant="warning" size="sm" onClick={() => handleStopAIBot(bot.id)}>
                        Stop
                      </Button>
                    ) : (
                      <Button variant="success" size="sm" onClick={() => handleStartAIBot(bot.id)}>
                        Start
                      </Button>
                    )}{' '}
                    <Button variant="danger" size="sm" onClick={() => handleDeleteAIBot(bot.id)}>
                      Delete
                    </Button>{' '}
                    <Button variant="info" size="sm" onClick={() => handleOpenAddKeywordModal(bot.id)}>
                      Add Keyword
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default AIBots;
